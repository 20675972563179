import { Directive } from 'vue'
// import store from '@/store'

// 点击空白处触发指令
const directive: Directive = {
  mounted (el, binding) {
    // const userInfo = store.getters['app/userInfo']
    // const val = binding.value
    // if (userInfo.sysRole !== '1' && !userInfo.auth.includes(val)) {
    //   el.parentNode.removeChild(el)
    // }
  }
}

export default directive
