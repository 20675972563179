import { env } from '@/env'

type UrlConfig = {
  BASE_API: string,
  IMAGE_PREFIX: string
}
const urlConfig = env()[process.env.NODE_ENV] as UrlConfig
const settings = {
  TITLE: '华夏恒丰能源监控平台',
  ...urlConfig
}

export default settings
