import m from 'moment'

m.locale('zh-cn')

export enum DateFormat {
  // eslint-disable-next-line no-unused-vars
  time = 'HH:mm:ss',
  // eslint-disable-next-line no-unused-vars
  date = 'YYYY-MM-DD',
  // eslint-disable-next-line no-unused-vars
  datetime = 'YYYY-MM-DD HH:mm:ss'
}

/**
 * 获取近几天
 * @param day
 */
export function recentDate (day: number): [string, string] {
  const today = m()
  const before = m().add(-day, 'days')
  return [before.format(DateFormat.date), today.format(DateFormat.date)]
}
export function getNearly7Day () {
  const days = []
  const date = new Date()
  for (let i = 0; i <= 24 * 6; i += 24) {
    // 今天加上前6天
    const dateItem = new Date(date.getTime() - i * 60 * 60 * 1000) // 使用当天时间戳减去以前的时间毫秒（小时*分*秒*毫秒）
    // const y = dateItem.getFullYear() // 获取年份
    let m = dateItem.getMonth() + 1 // 获取月份js月份从0开始，需要+1
    let d = dateItem.getDate() // 获取日期
    m = addDate0(m) // 给为单数的月份补零
    d = addDate0(d) // 给为单数的日期补零
    // const valueItem = y + '-' + m + '-' + d // 组合
    const valueItem = m + '-' + d // 组合
    days.push(valueItem) // 添加至数组
  }
  // 给日期加0
  function addDate0 (time) {
    if (time.toString().length === 1) {
      time = '0' + time.toString()
    }
    return time
  }
  return days.sort()
}
export function getHourNow () {
  const hourArr = []
  const nowHour = new Date().getHours()
  let i: any
  for (i = 0; i < nowHour; i++) {
    if (i < 10) {
      i = '0' + i
    }
    hourArr.push(i + ':00')
  }
  return hourArr
}
export const moment = m
