import router from '@/router'
import nProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { auth } from '@/router/interceptor/permission'
import settings from '@/settings'
import store from '@/store'

// TODO 路由前置守卫
router.beforeEach(async function (to, from, next) {
  nProgress.start()
  // 授权验证
  const authResult = await auth(to)
  if (authResult.state) {
    const { title } = to.meta
    document.title = `${title ? title + ' - ' : ''}${settings.TITLE}`
    next()
  } else {
    nProgress.done()
    next(authResult.toPath)
  }
})

// TODO 路由后置守卫
router.afterEach(function (to) {
  store.commit('app/setActiveMenu', to.name)
  nProgress.done()
})
