import request from '@/utils/request'

/**
 * 登录
 * @param params
 */
export const loginAPI = (params: any) => request.post('/sys/login', params)

/**
 * 获取用户信息
 */
export const getUserInfoAPI = () => request.get('/user/showHimself')

/**
 * 登出
 */
export const logoutAPI = () => request.post('/sys/logout')
