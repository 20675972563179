exports.env = function () {
  return {
    // 开发环境变量
    // 修改BASE_API需要重跑
    development: {
      // BASE_API: 'http://192.144.161.227:16100',
      // BASE_API: 'http://192.168.99.164:16100',
      BASE_API: 'http://39.102.66.242:16100',
      IMAGE_PREFIX: 'https://sfile.yuankonginfo.com/'
    },
    // 生产环境变量
    production: {
      // BASE_API: 'http://192.144.161.227:16100',
      BASE_API: 'http://39.102.66.242:16100',
      // BASE_API: 'https://iottest.yuankonginfo.com',
      IMAGE_PREFIX: 'https://sfile.yuankonginfo.com/'
    }
  }
}
