import request from './request'
import store from '@/store'

/**
 * 获取 Token
 */
export function getToken (): string {
  return localStorage.getItem('ato')
}

/**
 * 设置 Token
 * @param token
 */
export function setToken (token: string): void {
  request.defaults.headers.common['YK'] = token
  localStorage.setItem('ato', token)
}

/**
 * 清除 Token
 */
export function removeToken (): void {
  localStorage.clear()
  sessionStorage.clear()
  delete request.defaults.headers.common['YK']
  store.commit('app/setUserInfo', {})
}
