import { Directive } from 'vue'
import store from '@/store'

// 点击空白处触发指令
const directive: Directive = {
  mounted (el, binding) {
    const userInfo = store.getters['app/userInfo']
    const val = binding.value
    if (userInfo.sysRole !== '1' && !val.includes(userInfo.sysRole)) {
      el.parentNode.removeChild(el)
    }
  }
}

export default directive
