import { createModule } from '@/store'
import { User } from '@/typings/user'
import { getUserInfoAPI } from '@/api/auth'

export default createModule({
  namespaced: true,
  state: {
    // 用户信息
    userInfo: {} as User,
    // 当前选中的菜单
    activeMenu: '',
    collapse: false, // 折叠
    menuList: undefined,
    currentProject: undefined,
  },
  mutations: {
    setUserInfo (state, value) {
      state.userInfo = value
    },
    setActiveMenu (state, value) {
      state.activeMenu = value
    },
    setCollapse (state, val) {
      state.collapse = val
    },
    // 切换收缩状态
    collapseToggle (state) {
      state.collapse = !state.collapse
    },
    setMenuList (state, val) {
      state.menuList = val
    },
    setCurrentProject (state, val) {
      state.currentProject = val
    }
  },
  actions: {
    // TODO 更新用户信息
    updateUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfoAPI().then(res => {
          if (res.data.code === 200) {
            const data = res.data.payload.data
            data.auth = res.data.payload.auth?.authId.split(',') || []
            commit('setUserInfo', data)
            resolve({ state: true })
          } else {
            reject(res)
          }
        }).catch(reject)
      })
    }
  },
  getters: {
    userInfo: state => state.userInfo,
    activeMenu: state => state.activeMenu,
    collapse: state => state.collapse,
    menuList: state => state.menuList,
    currentProject: state => state.currentProject
  },
})
