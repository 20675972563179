import { RouteRecordRaw } from 'vue-router'
import Layout from '@views/layout-view/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/layout', component: Layout, redirect: '/layout/gis',
    children: [
      {
        path: 'gis', name: 'GisView',
        meta: { title: '项目总览', role: '1' },
        component: () => import('@views/gis-view/GisView.vue'),
      }
    ]
  },
  {
    path: '/layout', component: Layout, redirect: '/layout/menu',
    children: [
      {
        path: 'menu', name: 'Menu',
        component: () => import('@views/menu-view/MenuView.vue'),
        children: [
          {
            path: 'cockpit', name: 'CockpitView',
            meta: { title: '驾驶舱', role: '1' },
            component: () => import('@views/cockpit/CockpitView.vue'),
          },
          {
            path: 'computer-room', name: 'ComputerRoom',
            meta: { title: '高效机房', role: '1' },
            component: () => import('@views/ComputerRoom.vue'),
          },
          {
            path: 'all-computer-room', name: 'AllComputerRoom',
            meta: { title: '高效机房', role: '1', activeMenu: 'ComputerRoom' },
            component: () => import('@views/AllComputerRoom.vue'),
          },
          {
            path: 'digital-fresh-air', name: 'DigitalFreshAir',
            meta: { title: '数字新风', role: '1' },
            component: () => import('@views/digitalFreshAir/DigitalFreshAir.vue'),
          },
          {
            path: 'energy-analysis', name: 'EnergyAnalysis',
            meta: { title: '能耗分析', role: '1' },
            component: () => import('@views/EnergyAnalysis.vue'),
          },
          {
            path: 'efficiency-analysis', name: 'EfficiencyAnalysis',
            meta: { title: '能效分析', role: '1' },
            component: () => import('@views/EfficiencyAnalysis.vue'),
          },
          // {
          //   path: 'production-sales-analysis', name: 'ProductionSalesAnalysis',
          //   meta: { title: '产销分析', role: '4' },
          //   component: () => import('@views/production-analysis/ProductionAnalysis.vue'),
          // },
          // {
          //   path: 'historical-data', name: 'HistoricalData',
          //   meta: { title: '历史数据', role: '2' },
          //   component: () => import('@views/HistoricalData.vue'),
          // },
          {
            path: 'alarm', name: 'AlarmManage',
            meta: { title: '报警管理', role: '3' },
            component: () => import('@views/alarm-manage/AlarmManage.vue'),
          },
          {
            path: 'log', name: 'LogManage',
            meta: { title: '操作日志', role: '4' },
            component: () => import('@views/LogManage.vue'),
          },
          {
            path: 'user-manage', name: 'UserManage',
            meta: { title: '用户管理', roleId: ['2'] },
            component: () => import('@views/UserManage.vue'),
          },
          {
            path: 'role-manage', name: 'RoleManage',
            meta: { title: '权限管理', roleId: [] },
            component: () => import('@views/RoleManage.vue'),
          },
          {
            path: 'user-info', name: 'UserInfo',
            meta: { title: '账号信息' },
            component: () => import('@views/UserInfo.vue'),
          },
          {
            path: 'data-entry', name: 'DataEntry',
            meta: { title: '数据录入', roleId: ['2'] },
            component: () => import('@views/DataEntry.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/error', component: Layout,
    children: [
      {
        path: '403', name: 'Error403',
        meta: { title: '无权限' },
        component: () => import('@views/error/ErrorPage403.vue'),
      }
    ]
  }
]

export default { routes, menu: true }
