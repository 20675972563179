import dataset from './dataset'
import { NumberUtil } from '@/utils/object-util'

// TODO 创建字典
// 字典
const dictionary: Record<string, number[]> = {}
dataset.forEach((item, i) => {
  item.unitList.forEach((item2, j) => {
    dictionary[item2.unit] = [i, j]
  })
})
// 生成随机数组
export function randArray (len, min, max) {
  return Array.from({ length: len }, v => Math.floor(Math.random() * (max - min)) + min)
}
/**
 * 通过提高单位缩短数据长度
 * @param value 数据
 * @param unit 当前单位
 * @param desiredLen 最大长度
 * @param fractionDigits 保留位数(保留的位数归最大长度计算)
 * @param containsSymbol 符号是否归长度计算(".", "-")
 * @param containsDecimal 小数是否归长度计算
 */
export const matchUnit = (value: number, unit: string, desiredLen: number, fractionDigits: number, containsSymbol = false, containsDecimal = false) => {
  const ret = {
    value: NumberUtil.toFixed(value, fractionDigits),
    unit
  }
  // 如果合法，返回当前值
  if (verifyValue(value)) {
    return ret
  } else {
    // 获取对应单位的配置
    const dicIds = dictionary[unit]
    // 如果找不到返回原值
    if (dicIds) {
      // 获取单位列表和基准值
      const units = dataset[dicIds[0]].unitList
      const baseVal = value * units[dicIds[1]].ratio
      // 遍历比对哪一个单位合法
      for (let i = dicIds[1] + 1; i < units.length; i++) {
        const val = Number((baseVal / units[i].ratio).toFixed(fractionDigits))
        ret.value = val
        ret.unit = units[i].unit
        if (verifyValue(val)) break
      }
      return ret
    } else {
      return ret
    }
  }

  // 验证value是否合规
  function verifyValue (value: number) {
    let val = NumberUtil.toFixed(value, fractionDigits)
    if (!containsDecimal) val = ~~val
    if (!containsSymbol) {
      return delSymbol(val) <= desiredLen
    }
    return val.toString().length <= desiredLen
  }

  // 删除符号
  function delSymbol (arg: number) {
    return arg.toString().split('').filter(item => !isNaN(Number(item))).length
  }
}
